import './App.css';

function App() {
  return (
    <div className="App">
      <div className="profile">
        <img src="/NikhilGaur_Pic3.jpeg" alt="NikhilGaur_Pic" />
        <h1>Nikhil Gaur</h1>
        <h2>Information Technology</h2><br></br>
        <p>Interested in IT Systems, Music, History, Philosophy, Poetry, Medicine, Law, Linguistics and more. In no specific order.</p>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/ishavasya" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://mail.google.com/mail/u/0/?fs=1&to=rotten.pantsnew@gmail.com&tf=cm" target="_blank" rel="noopener noreferrer">Gmail</a>
          <a href="https://twitter.com/ishavasya3" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://www.instagram.com/ishavasya" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://youtube.com/@ishavasya" target="_blank" rel="noopener noreferrer">Youtube</a>
        </div>
      </div>
    </div>
  );
}

export default App;
